import React from "react";
import '../css/links_icon.css';

function ExternalLinkIcon({ url, icon, alt }) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img className="icon" src={icon} alt={alt} />
    </a>
  );
}

export default ExternalLinkIcon;

import React from 'react';
import Resume from './components/Data/Resume';

function App() {
  return (
    <div>      
      <Resume />
    </div>
  );
}

export default App;
